<template>
  <v-dialog
    v-model="dialog"
    v-if="$store.getters['auth/isLoggedIn']"
    width="570"
  >
    <template v-slot:activator="{ on }">
      <v-btn small outlined v-on="on" class="ml-2">
        {{ $t("pay") }}
      </v-btn>
    </template>
    <v-card id="select_token_payment">
      <v-card-title>
        {{ $t("pay_token_payment") }}
      </v-card-title>

      <div class="close">
        <v-btn icon @click="dialog = false" small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <v-card-text>
        <v-row>
          <v-col v-for="(pm, i) in payment_methods" :key="i">
            <v-card @click="pay(pm)" :disabled="pm === 'online' && !hasOnline">
              <v-card-title
                style="
                  font-size: 18px !important;
                  text-decoration: none !important;
                "
                class="text-decoration-none justify-center pt-5"
              >
                {{ $t("expenses." + pm) }}
              </v-card-title>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-dialog
        width="400"
        v-model="link_dialog"
        v-if="$store.getters['auth/isLoggedIn']"
      >
        <v-card id="consentFormLink">
          <v-card-title
            style="text-decoration: underline; text-transform: uppercase"
          >
            Vista del cliente:
          </v-card-title>
          <div class="close">
            <v-btn icon @click="link_dialog = false">
              <v-icon id="iconClose">mdi-close</v-icon>
            </v-btn>
          </div>
          <v-card-text class="pb-2">
            <v-text-field outlined dense id="URL" readonly v-model="URL">
              <template slot="prepend-inner">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon class="mr-2" v-on="on" @click="copy()">
                      mdi-content-copy
                    </v-icon>
                  </template>
                  {{ $t("copy") }}
                </v-tooltip>
              </template>
            </v-text-field>
          </v-card-text>
          <v-card-actions class="pt-0">
            <v-spacer />
            <v-btn height="30px" elevation="0" color="primary" @click="goToPay">
              {{ $t("to_complete") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </v-dialog>

  <v-btn
    v-else
    small
    outlined
    @click="
      $router.push({
        name: 'customer_payment',
        params: {
          token: appointment.studio.token,
          appointment_id: appointment.id,
        },
      })
    "
  >
    {{ $t("pay") }}
  </v-btn>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      dialog: false,
      payment_methods: ["cash", "card", "online"],
      link_dialog: false,
    };
  },
  props: ["appointment", "appointmentTokenPayment"],

  computed: {
    URL() {
      return `${window.location.origin}/?#/inscription/studio/${this.appointment.studio.token}/appointment/${this.appointment.id}/appointment_token/${this.appointmentTokenPayment.id}`;
    },
    hasOnline() {
      let s = this.appointment.studio.user.setting.find(
        (x) => x.key === "pm_stripe_id"
      );
      if (s) return s.value !== 0;
      return false;
    },
  },
  methods: {
    copy() {
      /* Get the text field */
      var copyText = document.getElementById("URL");

      /* Select the text field */
      copyText.select();
      copyText.setSelectionRange(0, 99999); /*For mobile devices*/

      /* Copy the text inside the text field */
      document.execCommand("copy");
      this.link_dialog = false;
      this.$alert(this.$t("link_copy_success"), "", "");
    },
    ...mapActions("appointments", ["generateTokenPayment"]),
    pay(pm) {
      if (pm === "online") this.link_dialog = true;
      else {
        this.generateTokenPayment({ id: this.appointment.id, method: pm }).then(
          () => {
            this.dialog = false;
            this.$emit("update");
          }
        );
      }
    },
    goToPay() {
      this.$router.push({
        name: "customer_payment",
        params: {
          token: this.appointment.studio.token,
          appointment_id: this.appointment.id,
          appointmentTokenPayment_id: this.appointmentTokenPayment.id,
        },
      });
    },
  },
};
</script>

<style lang="sass">
#select_token_payment
  .v-card
    border: 1px solid var(--v-primary-base)
    height: 75px
    &.v-card--disabled
      border-color: var(--v-gris1-base)
      .v-card__title
        color: var(--v-gris1-base)
    @media(max-width: 600px)
      height: 100px
</style>
