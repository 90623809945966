var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$store.getters['auth/isLoggedIn'])?_c('v-dialog',{attrs:{"width":"570"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"small":"","outlined":""}},on),[_vm._v(" "+_vm._s(_vm.$t("pay"))+" ")])]}}],null,false,3392728604),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"id":"select_token_payment"}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t("pay_token_payment"))+" ")]),_c('div',{staticClass:"close"},[_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('v-row',_vm._l((_vm.payment_methods),function(pm,i){return _c('v-col',{key:i},[_c('v-card',{attrs:{"disabled":pm === 'online' && !_vm.hasOnline},on:{"click":function($event){return _vm.pay(pm)}}},[_c('v-card-title',{staticClass:"text-decoration-none justify-center pt-5",staticStyle:{"font-size":"18px !important","text-decoration":"none !important"}},[_vm._v(" "+_vm._s(_vm.$t("expenses." + pm))+" ")])],1)],1)}),1)],1),(_vm.$store.getters['auth/isLoggedIn'])?_c('v-dialog',{attrs:{"width":"400"},model:{value:(_vm.link_dialog),callback:function ($$v) {_vm.link_dialog=$$v},expression:"link_dialog"}},[_c('v-card',{attrs:{"id":"consentFormLink"}},[_c('v-card-title',{staticStyle:{"text-decoration":"underline","text-transform":"uppercase"}},[_vm._v(" Vista del cliente: ")]),_c('div',{staticClass:"close"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.link_dialog = false}}},[_c('v-icon',{attrs:{"id":"iconClose"}},[_vm._v("mdi-close")])],1)],1),_c('v-card-text',{staticClass:"pb-2"},[_c('v-text-field',{attrs:{"outlined":"","dense":"","id":"URL","readonly":""},model:{value:(_vm.URL),callback:function ($$v) {_vm.URL=$$v},expression:"URL"}},[_c('template',{slot:"prepend-inner"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",on:{"click":function($event){return _vm.copy()}}},on),[_vm._v(" mdi-content-copy ")])]}}],null,false,2496974216)},[_vm._v(" "+_vm._s(_vm.$t("copy"))+" ")])],1)],2)],1),_c('v-card-actions',{staticClass:"pt-0"},[_c('v-spacer'),_c('v-btn',{attrs:{"height":"30px","elevation":"0","color":"primary"},on:{"click":_vm.goToPay}},[_vm._v(" "+_vm._s(_vm.$t("to_complete"))+" ")])],1)],1)],1):_vm._e()],1)],1):_c('v-btn',{attrs:{"small":"","outlined":""},on:{"click":function($event){return _vm.$router.push({
      name: 'customer_payment',
      params: {
        token: _vm.appointment.studio.token,
        appointment_id: _vm.appointment.id,
      },
    })}}},[_vm._v(" "+_vm._s(_vm.$t("pay"))+" ")])}
var staticRenderFns = []

export { render, staticRenderFns }